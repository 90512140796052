import React from "react";
import { BsLinkedin, BsGithub } from "react-icons/bs";
import { FaMeetup } from "react-icons/fa";

const SocialMedia = () => {
  return (
    <div className="app__social">
      <div>
        <a
          href="https://www.linkedin.com/in/thamsanqa-mpofu/"
          target="_blank"
          rel="noreferrer"
        >
          <BsLinkedin />
        </a>
      </div>
      <div>
        <a
          href="https://github.com/Thamue1892/"
          target="_blank"
          rel="noreferrer"
        >
          <BsGithub />
        </a>
      </div>
      <div>
        <a
          href="https://www.meetup.com/members/187687864/"
          target="_blank"
          rel="noreferrer"
        >
          <FaMeetup />
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
